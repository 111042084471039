import { onDomReady } from '../utils/dom-ready';

/**
 * The path to the image used in the landing hero.
 * Required to detect when image has been loaded.
 */
import mallImage from '../images/mall-color.jpg';

/**
 * The minimum amount of time in seconds
 * for the placeholder to be displayed.
 */
const MINIMUM_PLACEHOLDER_SECONDS = 1;

/**
 * Find all elements by container name.
 *
 * @param {String} name Name of element
 * @returns {NodeList}
 */
function findAllContainers(name) {
  return document.querySelectorAll(`[data-landing-container="${name}"]`);
}

/**
 * Find an element by container name.
 *
 * @param {String} name Name of element
 * @returns {Node}
 */
function findContainer(name) {
  return document.querySelector(`[data-landing-container="${name}"]`);
}

/**
 * Callback for landing hero image loading.
 *
 * @param {Node} landingImage The landing image DOM element
 * @returns {undefined}
 */
function handleLandingImageLoaded(landingImage) {
  /**
   * This is an `<img>` tag which was only used for
   * loading detection. Let's remove it now.
   */
  landingImage.remove();

  const containerElement = findContainer('hero');
  const placeholders = findAllContainers('placeholder');
  const animatedImage = findContainer('hero-image');
  const animatedLogo = findContainer('logo-container');
  const animatedLeft = findContainer('left-section');
  const animatedRight = findContainer('right-section');

  animatedImage.classList.add(
    'animate__animated',
    'animate__fadeIn',
    'animate__faster'
  );

  animatedLogo.classList.add(
    'animate__animated',
    'animate__fadeIn',
    'animate__delay-4s',
    'animate__slower'
  );

  animatedLeft.classList.add(
    'animate__animated',
    'animate__fadeInLeft',
    'animate__slow'
  );

  animatedRight.classList.add(
    'animate__animated',
    'animate__fadeInRight',
    'animate__delay-2s',
    'animate__slow'
  );

  /**
   * On the last animation, color the landing hero image.
   */
  animatedRight.addEventListener('animationend', () => {
    const colorClass = animatedImage.getAttribute('data-class-modifier-color');
    animatedImage.classList.add(colorClass);
  });

  /**
   * Garbage collect any placeholders/spinners.
   */
  placeholders.forEach((placeholder) => placeholder.remove());

  /**
   * Ensure that all elements are visible for animation.
   */
  containerElement.removeAttribute('aria-busy');
}

onDomReady(() => {
  let isImageLoaded = false;
  let seconds = 0;

  const timer = setInterval(() => {
    seconds += 1;

    if (isImageLoaded && seconds >= MINIMUM_PLACEHOLDER_SECONDS) {
      handleLandingImageLoaded(landingImage);
      clearInterval(timer);
    }
  }, 1000);

  const landingImage = document.createElement('img');
  landingImage.onload = () => {
    isImageLoaded = true;
  };
  landingImage.src = mallImage;
  landingImage.setAttribute('hidden', '');

  document.body.append(landingImage);
});
