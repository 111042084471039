/**
 * Utility for detecting when DOM is ready.
 *
 * @param {Function} callback Called when DOM is ready
 * @returns {undefined}
 */
export function onDomReady(callback) {
  document.readyState === 'loading'
    ? document.addEventListener('DOMContentLoaded', callback)
    : callback();
}
